.elementor-categorylist {
  .block_content.row {
    margin-left: -15px;
    margin-right: -15px;
  }
  .categoryblock {
    padding: 15px;
  }
  .grid {
    @include media-breakpoint-only(xs) {
      /* max 543 */
      &.cols-xs-1 {
        .categoryblock {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      &.cols-xs-2 {
        .categoryblock {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
      &.cols-xs-3 {
        .categoryblock {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
    }
    @include media-breakpoint-only(sm) {
      /* min 544 max 767 */
      &.cols-sm-1 {
        .categoryblock {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      &.cols-sm-2 {
        .categoryblock {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
      &.cols-sm-3 {
        .categoryblock {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
      &.cols-sm-4 {
        .categoryblock {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
    }
    @include media-breakpoint-only(md) {
      /* min 768 max 991 */
      &.cols-md-1 {
        .categoryblock {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      &.cols-md-2 {
        .categoryblock {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
      &.cols-md-3 {
        .categoryblock {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
      &.cols-md-4 {
        .categoryblock {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
      &.cols-md-5 {
        .categoryblock {
          flex: 0 0 20%;
          max-width: 20%;
        }
      }
    }
    @include media-breakpoint-only(lg) {
      /* min 992 max 1199 */
      &.cols-lg-1 {
        .categoryblock {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      &.cols-lg-2 {
        .categoryblock {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
      &.cols-lg-3 {
        .categoryblock {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
      &.cols-lg-4 {
        .categoryblock {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
      &.cols-lg-5 {
        .categoryblock {
          flex: 0 0 20%;
          max-width: 20%;
        }
      }
      &.cols-lg-6 {
        .categoryblock {
          flex: 0 0 16.66667%;
          max-width: 16.66667%;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      &.cols-xl-1 {
        .categoryblock {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      &.cols-xl-2 {
        .categoryblock {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
      &.cols-xl-3 {
        .categoryblock {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
      &.cols-xl-4 {
        .categoryblock {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
      &.cols-xl-5 {
        .categoryblock {
          flex: 0 0 20%;
          max-width: 20%;
        }
      }
      &.cols-xl-6 {
        .categoryblock {
          flex: 0 0 16.66667%;
          max-width: 16.66667%;
        }
      }
      &.cols-xl-7 {
        .categoryblock {
          flex: 0 0 14.2%;
          max-width: 14.2%;
        }
      }
      &.cols-xl-8 {
        .categoryblock {
          flex: 0 0 12.5%;
          max-width: 12.5%;
        }
      }
    }
  }
  .style1 {
    position: relative;
    .categoryblock {
      text-align: center;
      .category-wrap {
        justify-content: center;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: 15px 5px 15px 5px;
      }
      .categoryimage {
        cursor: pointer;
        display: flex;
        align-items: stretch;
        justify-content: center;
        margin-bottom: 10px;
        @include transition(all 0.5s ease 0s);
        img {
          border-radius: 50%;
        }
      }
      .categorylist {
        .cate-heading {
          a {
            font-size: 16px;
            font-weight: 600;
            text-transform: capitalize;
            color: #333333;
            letter-spacing: 0.3px;
          }
        }
        .categories-info-content {
          .sub-categories {
            margin: 5px 0 0;
          }
        }
      }
    }
  }
  .style1,
  .style3 {
    .categoryblock {
      .show-all-cate {
        display: block;
        margin-top: 5px;
        margin-bottom: 10px;
        a {
          font-weight: 600;
          color: #333333;
          text-transform: capitalize;
          font-size: 14px;
          letter-spacing: 0.3px;
          text-decoration: underline;
        }
      }
    }
  }
  .style2 {
    .categoryblock {
      .category-wrap {
        overflow: hidden;
        background: #fff;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: 0;
        .categoryimage {
          text-align: center;
          margin-bottom: 10px;
        }
      }
      .categorylist {
        .category-title {
          padding: 10px 5px;
          background: #d8d8d8;
          text-align: center;
        }
        .cate-heading {
          a {
            color: #333333;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.5px;
            text-transform: uppercase;
          }
        }
        .categories-info-content {
          .sub-categories {
            text-align: left;
            padding: 10px;
            margin: 0;
            display: flex;
            flex-flow: row wrap;
            li {
              padding: 5px;
              position: relative;
              flex: 0 0 50%;
              max-width: 50%;
              a {
                font-size: 14px;
                position: relative;
                display: inline-flex;
                align-items: flex-start;
                justify-content: center;
                &:before {
                  font-family: "Material Icons";
                  content: "\e5cc";
                  margin-right: 5px;
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }
  .style3 {
    .categoryblock {
      .category-wrap {
        display: flex;
        flex-flow: row nowrap;
        .categoryimage {
          flex: 0 0 40%;
          max-width: 40%;
        }
        .categorylist {
          flex: 1 1 auto;
          padding-left: 15px;
          margin-left: -15px;
          .cate-heading {
            display: block;
            margin-bottom: 5px;
            a {
              font-size: 15px;
              text-transform: uppercase;
              font-weight: 600;
              letter-spacing: 0.5px;
            }
          }
          .categories-info-content {
            .sub-categories {
              margin: 0;
            }
          }
        }
      }
      .show-all-cate {
        margin-bottom: 0;
      }
    }
  }
}
