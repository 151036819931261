.blockcart.inactive {
  color: $gray-600;
  background-color: $gray-200;
  .material-icons {
    color: $gray-600;
  }
}
.product-line-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .input-group.bootstrap-touchspin {
    max-width: 130px;
    border: 1px solid #f5f5f5;
  }
  .product-price {
    color: $gray-darker;
    line-height: 36px;
  }
  .cart-line-product-actions {
    .remove-from-cart {
      color: $gray-darker;
      i {
        font-size: 20px;
      }
    }
  }
}
/** CONTENT LEFT **/
.product-line-grid-right {
  .bootstrap-touchspin {
    > .form-control,
    > .input-group {
      color: $gray-darker;
      background-color: white;
      text-align: center;
      padding: 2px 8px;
      border:0;
      box-shadow: none;
    }
  }
}
.product-line-grid {
  .media {
    img {
      max-width: 100%;
    }
    .media-body {
      .product-line__title {
        display: inline-block;
        line-height: inherit;
        text-align: left;
        white-space: inherit;
        margin-bottom: 5px;
      }
      > span {
        display: inline-block;
      }
      > .out-of-stock {
        color: red;
      }
      > .available {
        color: $success;
      }
      > .unit-price-cart {
        font-size: 14px;
      }
      .product-price {
        margin-bottom: 5px;
        font-size: 14px;
        .product-discount {
          .regular-price {
            font-size: 14px;
            margin: 0;
            display: inline-block;
            vertical-align: middle;
          }
        }
        &.has-discount {
          .discount {
            font-size: 12px;
            padding: 2px 6px;
            margin: 0 5px;
            display: inline-block;
            vertical-align: middle;
          }
        }
        .product-discount, .current-price {
          display: inline-block;
          vertical-align: middle;
        }
      }
      .product-price {
        display: flex;
        align-items: center;
        .discount {
          font-size: $font-size-sm;
          line-height: 1;
        }
      }
      .product-discount {
        margin-right: $spacer/2;
      }
    }
  }
}
.product-line__img {
  margin-right: $spacer;
}
@include media-breakpoint-down(md) {
  .product-line__img {
    width: 100px;
    height: auto;
  }
  .product-line-actions {
    justify-content: flex-end;
    margin-top: $spacer;
    .product-price {
      margin: 0 $spacer * 2;
    }
  }
}
@include media-breakpoint-down(sm) {
  .product-line__img {
    width: 50px;
  }
  .product-line-actions {
    display: flex;
    justify-content: space-between;
    .product-price {
      margin: 0 $spacer;
    }
  }
}

.cart__card-body {
  position: relative;
  .cart__card-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    display: none;
    background-color: rgba($white, 0.85);
    opacity: 0;
    transition: $transition-fade;
    z-index: 3;
  }
  &.is--loading {
    .cart__card-loader {
      display: flex;
      opacity: 1;
    }
  }
}

#blockcart-modal {
  .modal-header {
    border-color: $border-color;
  }
  .modal-body {
    padding: 20px;
    @include media-breakpoint-down(sm) {
      padding: 15px;
    }
    .divide-right span {
      display: inline-block;
      margin-bottom: 5px;
    }
  }
  .cart-content {
    background: transparent;
    padding: 0;
  }
  .modal-title {
    font-weight: 400;
    font-size: 16px;
    i {
      font-weight: 400;
      margin: 0 10px;
    }
  }
  .product-name {
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 10px;
  }
  .product-attributes {
    margin-top: 8px;
  }
  p {
    margin: 0;
    font-size: 90%;
    strong {
      font-weight: normal;
    }
  }
  .cart-products-count {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .cart-content {
    .total-price {
      font-size: 120%;
      margin-top: 10px;
    }
    .cart-content-btn {
      margin-top: 20px;
      @include media-breakpoint-down(md) {
        margin-top: 15px;
      }
      .btn {
        @include media-breakpoint-down(xs) {
          margin-bottom: 10px;
        }
      }
    }
    button {
      margin-right: 5px;
    }
  }
}