.header-style-2 {
	@include media-breakpoint-up(lg) { /* min 992 */
		.searchwrap {
			padding: 0;
			margin:0;
		}
	}
}
@include media-breakpoint-up(lg) { /* min 992 */
	.searchwrap {
		display:flex;
		align-items:center;
		flex:1;
		justify-content:center;
		@include media-breakpoint-only(xl) {
			padding: 0 120px;
		}
		@include media-breakpoint-only(lg) { /* min 992 max 1199 */
			padding: 0 30px;
		}
	}
}
.tdsearchblock {
	flex:1;
}
#header-search-btn {
	padding-right: 8px;
	position: relative;
	cursor: pointer;
	.header-search-btn {
		i {
			font-size: 22px;
		}
	}
	.dropdown-menu {
		padding: 0;
		min-width: 34rem;
		right: 0;
		left: auto;
		border: none;
	}
}

#search_block_top {
	display:flex;
	align-items:stretch;
	position: relative;
	width: 100%;
	#searchbox {
		float: left;
		width: 100%;
		overflow: hidden;
		position: relative;
		border-radius: 4px;
		@include media-breakpoint-down(md) { /* max 991 */
			float: none;
			text-align: center;
			margin: auto;
		}
		.searchboxform-control {
			width: 30%;
			float: left;
			overflow: hidden;
			position: relative;
			&:before{
				content: "";
				height: 30px;
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 1px;
			}
			&:after{
				content: "\e5c5";
				font-family: "Material Icons";
				height: 40px;
				pointer-events: none;
				position: absolute;
				right: 15px;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 10%;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				font-size: 20px;
			}
			#search_category {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				padding: 10px 30px 10px 10px;
				border: medium none;
				background:none;
				line-height:normal;
				height: 44px;
				overflow: auto;
				width: 100%;
				font-size: 14px;
				@include media-breakpoint-only(lg) { /* min 992 max 1199 */
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				@include media-breakpoint-down(sm) { /* max 767 */
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				option {
					padding:2px;
					text-transform: capitalize;
				}
			}
		}
	}
	.btn.button-search {
		position: relative;
		float: right;
		background: none;
		border:none;
		padding: 8px 15px;
		border-radius: 0;
		text-transform: uppercase;
		letter-spacing: 1px;
		line-height: 16px;
		height: 44px;
		@include transition(all 0.3s ease 0s);
		i {
			font-size: 16px;
		}
		&:before {
			content: "";
			background: $border-color;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			height: 30px;
			width: 1px;
		}
	}
	.input-wrapper {
		overflow: hidden;
	}
	#search_query_top {
		border: none;
		height: 44px;
		padding: 10px 20px;
		width: 100%;
		border-radius: 0;
		background: none;
		box-shadow: none;
	}
	#tdsearch_result_content {
		position: absolute;
		width: 100%;
		top: 100%;
		z-index: 11;
		display: none;
		text-align: left;
		background: $light-color;
		border:1px solid $border-color;
		max-height: 400px;
		overflow-y: auto;
		.char_limit {
			padding: 10px;
		}
		.ajax-search-content {
			.top-content {
				padding: 10px;
				border-bottom: 1px solid $border-color;
				word-wrap: break-word;
				color: $gray;
			}
			.items-list {
				padding: 10px;
				.item {
					a {
						padding: 5px;
						display: inline-block;
						width: 100%;
						border: medium none;
						border-radius: 0;
						margin: 0;
						cursor: pointer;
						.searchImg {
							float: left;
							height: auto;
							margin: 0 15px 0 0;
							max-width: 80px;
						}
						.category-name {
							display: block;
							font-size: 14px;
							margin-bottom: 5px;
						}
						.product-name {
							display: block;
							font-size: 15px;
							font-weight: 500;
							margin: 0 0 5px;
						}
						.content_price {
							display: block;
							.regular-price {
								text-decoration: line-through;
								font-weight: normal;
							}
							.sale-percentage {
								margin: 0 3px;
							}
							.price {
								font-weight: 500;
							}
						}
					}
				}
			}
		}
	}
}