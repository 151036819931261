/*** HORIZONTAL MENU ***/


/*** MOBILE MENU ***/

#_desktop_bitmegamenu-mobile{
  display: none !important;
}

.title_horizontal {
  position: relative;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  &:before {
    font-family: Material Icons;
    content: "\E3C7";
    font-size: 22px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}
//general
#bitmegamenu-mobile {
  clear: both;
  li{
    position: relative;
  }
  a{
    display: block;
    padding: 5px 15px;
    line-height: 30px;
  }
  .mm-expand{
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    right: 0;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    z-index: 2;
  }
  li ul {
    display: none;
    margin-left: 15px;
  }
}
