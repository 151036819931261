img[data-lazy] {
  display: none;
}
.text-underline{
  text-decoration: underline;
}
.cursor-pointer{
  cursor: pointer;
}
.visible--desktop{
  @include media-breakpoint-mobile(){
    display: none;
  }
}
.visible--mobile{
  @include media-breakpoint-desktop(){
    display: none;
  }
}
.d--none{
  display: none;
}
.d--block{
  display: block;
}
.d--flex-between{
  display: flex;
  justify-content: space-between;
}
.mb--0{
  margin-bottom: 0;
}
.text--center{
  text-align: center;
}
.text--right{
  text-align: right;

}
