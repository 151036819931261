.easyzoom {
	display: inline-block;
	*display: inline;
	*zoom: 1;
}

.easyzoom img {
	vertical-align: bottom;
}

.easyzoom.is-loading img {
	cursor: progress;
}

.easyzoom.is-ready img {
	cursor: crosshair;
}

.easyzoom.is-error img {
	cursor: not-allowed;
}

.easyzoom-notice {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10;
	height: 32px;
	line-height: 32px;
	text-align: center;
	width: 32px;
	margin-top: -16px;
	margin-left: -16px;
	border-radius: 2px
}

.easyzoom-flyout {
	position: absolute;
	z-index: 100;
	overflow: hidden;
	background: #FFF;
}

.easyzoom--overlay .easyzoom-flyout {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.easyzoom--adjacent .easyzoom-flyout {
	top: 0;
	left: 100%;
	width: 100%;
	height: 100%;
	margin-left: 20px;
}

.easyzoom-flyout {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	bottom: 0;
	top: 0;
	right: 0;
	z-index: 1;
}