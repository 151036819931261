#top-menu {
  margin: 0;
  position: relative;
  @extend .d-inline-flex;
  > li {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    > a {
      color: #666666;
      display: block;
      position: relative;
      text-transform: uppercase;
      white-space: normal;
    }
    > .nav-items {
      visibility: hidden;
      z-index: 999;
      left: 0;
      padding: 15px 10px;
      position: absolute;
      opacity: 0;
      top: 100%;
      margin: 0;
      min-width: 200px;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      background: #fff;
      border: 1px solid #e5e5e5;
      display: inline-flex;
      flex-wrap: wrap;
      @include transition(all 0.5s ease 0s);
      &.threecolumn {
        width: 675px;
        > li {
          width: 33.333%;
          &:nth-child(-n+3) {
            margin-top: 0;
          }
        }
      }
      &.twocolumn {
        width: 450px;
        > li {
          width: 50%;
          &:nth-child(-n+2) {
            margin-top: 0;
          }
        }
      }
      &.onecolumn {
        width: 225px;
        > li {
          width: 100%;
          &:nth-child(-n+1) {
            margin-top: 0;
          }
        }
      }
    }
    > ul[data-depth="1"]  {
      > li {
        display: inline-flex;
        flex-direction: column;
        vertical-align: top;
        padding: 0 10px;
        margin-top: 10px;
        > a {
          display: block;
          position: relative;
          font-size: 14px;
          font-weight: bold;
          padding: 0;
          text-transform: uppercase;
          white-space: normal;
        }
        &:hover {
           > a {
            color: $black;
           }
        }
        .child-menu {
          li {
            a {
              display: block;
              font-size: 14px;
              font-weight: normal;
              line-height: 16px;
              padding: 0 0 10px;
              white-space: normal;
              position: relative;
            }
          }
        }
      }
      .nav-items {
        margin-top: 6px;
        li {
          a {
            padding: 3px 0;
          }
        }
        .nav-items {
          margin: 0 0 0 15px;
        }
      }
    }
    &:hover {
      > a {
        color: $black;
      }
      > .nav-items {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.header-top{
  position: relative;
}
.menu-top{
  @extend .d-flex;
  margin: 0;
}
.menu__item-link--top{
  color: $menu-topitem-color;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  padding: $spacer/2;
}

@include media-breakpoint-up(md) {
  .menu__item-header {
    height: 100%;
  }
  .menu-sub {
    position: absolute;
    opacity: 0;
    width: 100%;
    left: 0;
    top: 60px;
    z-index: -1;
    transition: all .5s ease;
  }
  .menu-sub__content > ul {
    padding: 10px;
    background-color: #fff;
    box-shadow: $box-shadow;
  }
  .menu__item--active .menu-sub {
    z-index: 2;
    opacity: 1;
  }
  .menu-sub__list--1 {
    display: flex;
  }
  .menu__item--1 {
    padding-right: 10px;
    padding-left: 10px;

  }
  .menu__item-link--1 {
    text-transform: uppercase;
    color: $black;
    .menu__item-link {
      color: $body-color;
    }
  }
}

//mobile
@include media-breakpoint-down(sm) {
  .menu-top{
    flex-direction: column;
    margin-bottom: $spacer;
  }
  .menu-sub{
    width: 100%;
    transition: all .3s ease-out;
  }
  //remove padding from container class
  .menu-sub__content{
    padding: 0;
  }
  .menu__item-link--top{
    padding: 0;
  }
  .js-top-menu{
    margin-right: -$modal-inner-padding;
    margin-left: -$modal-inner-padding;
  }
  .menu__item{
    //padding: $spacer/2;
    border-bottom: 1px solid $menu-item-border-color;
    border-top: 1px solid $menu-item-border-color;
  }
  .menu__item--top:not(:first-child){
    border-top:0;
  }
  .menu__item--sub:last-child,
  .menu__item--sub:first-child:not(:last-child){
    border-bottom: 0;
  }
  .menu__item-header{
    display: flex;
    justify-content: space-between;
    padding: $menu-item-padding;
  }
  .menu__item-link--nosubmenu{
    padding: $menu-item-padding;
    display: block;
  }
  .menu-sub.show{
    background: $menu-sub-mobile-bg-color;
  }

  //increase offset
  .menu__item--1 {
    .menu__item-link--nosubmenu,
    .menu__item-header{
      margin-left: 10px;
    }
  }
  .menu__item--2 {
    .menu__item-link--nosubmenu,
    .menu__item-header{
      margin-left: 20px;
    }
  }
  .menu__item--3 {
    .menu__item-link--nosubmenu,
    .menu__item-header{
      margin-left: 30px;
    }
  }
}

#mobile_top_menu_wrapper{
  &.modal{
    .modal-header{
      border-bottom:0;
    }
    .modal-body{
      padding-top:0;
    }
  }
}

.menu__collapseicon{
  width: 3rem;
  height: 1.5rem;
  display: block;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
}
