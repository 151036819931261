.wishlist {
	.td-wishlist-button {
		&:after {
			display: none;
		}
	}
	.td-list-wishlist {
		min-width: 135px;
		max-height: 105px;
		padding: 0;
		overflow-y: auto;
		bottom: 100%;
		top: auto;
		margin: 0 0 5px;
		.dropdown-item {
			padding: 5px 8px;
			border: medium none;
			&:hover, &.added {
				background: transparent;
			}
		}
	}
}



.td-modal-send-wishlist {
	@include media-breakpoint-down(xs) { /* max 543 */
		.modal-dialog {
			width: auto;
		}
	}
	.modal-body {
		.form-send-wishlist {
			.td-fake-send-wishlist {
				display: none;
			}
		}
	}
	.modal-footer {
		display: inline-block;
		vertical-align: middle;
		.btn-secondary, .td-modal-send-wishlist-bt {
			float: right;
		}
	}
}

#mywishlist {
	.page-header {
		margin: 0 0 20px;
		padding: 0;
		background: transparent;
		border:0;
		h1 {
			border:0;
			padding: 0;
		}
	}
	.card-block {
		box-shadow: none!important;
	}
	.delete-wishlist-product {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
	}
	.wishlist-product-action {
		position: relative;
		.wishlist-product-save-button {
			float: left;
		}
		.td-wishlist-button-dropdown {
			float: right;
			.td-wishlist-button {
			}
			.td-list-wishlist {
				min-width: 135px;
				max-height: 105px;
				padding: 0;
				overflow-y: auto;
				overflow-x: hidden;
				margin-bottom: 2px;
				bottom: 100%;
				height: 100%;
				right: 0;
				top: auto;
				left: auto;
				border:0;
				.dropdown-item {
					padding: 5px 8px;
					border: medium none;
					&:hover, &.added {
						background: transparent;
					}
				}
			}
		}
	}
	.product-description {
		padding: 10px;
	}
}

.td-modal-wishlist {
	.modal-dialog {
		@include media-breakpoint-up(md) { /* min 768 */
			min-width: 600px;
			width: 100%;
		}
	}
	.modal-content {
		overflow: hidden;
		.modal-title {
			font-weight: 400;
		}
	}
	.modal-footer {
		display: none;
	}
	&.enable-action .modal-footer {
		display: block;
	}
}

.list-wishlist tr {
  -moz-transition: background-color 1.5s;
  -webkit-transition: background-color 1.5s;
  -o-transition: background-color 1.5s;
  transition: background-color 1.5s;
}

.list-wishlist {
	margin: 20px 0;
	overflow-x: auto;
	tr {
		&.new, &.active, &.show.active {
			background-color: #DBE5F5 !important;
		}
	}
}

.wishlist-item {
	&.added {
		background-color: #2fb5d2;
		color: #fff;
		&:hover {
			background-color: #2fb5d2;
			color: #fff;
		}
	}
	&:hover {
		background-color: #f7f7f9;
	}
}

.move-wishlist-item:hover {
	background-color: #f7f7f9;
}
         
.view-wishlist-product {
	float: left;
}

.list-wishlist tr.show {
	background-color: #d9edf7 !important;
}

.send-wishlist {
	display: none;
	margin-bottom: 20px;
}

.send_wishlist_msg {
	display: none;
}

.td-modal-reset-send-wishlist {
	float: left;
}

.send_wishlist_success {
	color: #5cb85c;
}

.send_wishlist_error {
	color: #f0ad4e;
}

.send_wishlist_form_content {
	.has-success input, .has-warning input {
		pointer-events: none;
	}
}