/* list */
#products {
  #product-list-top {
    .products-selection {
      margin-top: 20px;
      margin-bottom: 10px;
      .view-switcher {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > a {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #777;
          opacity: 0.5;
          border-radius: 3px;
          margin-right: 15px;
          i {
            font-size: 28px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }
          &.current {
            opacity: 1;
          }
        }
      }
      .box-sort-by {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        .sort-order {
          margin-left: 10px;
          @include media-breakpoint-down(sm) {
            /* max 767 */
            margin: 0;
          }
        }
      }
    }
  }
  .d-md-none {
    display: none;
  }
}
#product-list {
  .products {
    .product-miniature {
      &.list {
        flex: 0 0 100%;
        max-width: 100%;
        transition: background 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease, outline 0.3s ease;
        &:hover {
          .left-block {
            img {
              @include transition(all 0.7s ease 0s);
              &.img_1 {
                opacity: 1;
              }
            }
          }
        }
        .thumbnail-container {
          position: relative;
          @include media-breakpoint-down(xs) {
             display: inline-block;
          }
          .thumbnail {
            position: relative;
            overflow: hidden;
          }
        }
        .left-block {
          display: block;
          text-align: center;
          position: relative;
          img {
            margin: 0 auto;
            max-width: 100%;
            height: auto;
            @include transition(all 0.7s ease 0s);
            &.img_1 {
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              opacity: 0;
            }
          }
        }
        .center-block {
          @include media-breakpoint-down(xs) { /* max 543 */
            margin-top: 15px;
            text-align: center;
            justify-content: center;
          }
          .products-variants {
            .variant-links {
              align-items: center;
              justify-content: flex-start;
              flex-flow: row wrap;
              @include media-breakpoint-down(xs) { /* max 543 */
                justify-content: center;
              }
              .color {
                margin: 5px 5px 5px 0;
                width: 25px;
                height: 25px;
                border-radius: 100%;
                border: 3px solid #fff;
                position: relative;
                @include transition(opacity 0.3s ease 0s);
                vertical-align: top;
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
                &:before {
                  content: "\f00c";
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-family: $font-icon;
                  position: absolute;
                  color: #f0f0f0;
                  font-size: 12px;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  margin: auto;
                  text-align: center;
                  visibility: hidden;
                  opacity: 0;
                  filter: alpha(opacity=0);
                  @include transition(opacity 0.3s ease 0s);
                }
              }
            }
          }
        }
        .right-block {
          @include media-breakpoint-down(xs) { /* max 543 */
            margin-top: 5px;
            text-align: center;
          }
          .wishlist {
            .dropdown-menu {
              right: 0;
              left: auto;
            }
          }
          .product-add-to-cart {
            margin-top: 5px;
            .btn-primary {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              svg {
                margin-right: 5px;
              }
              span {
                display: inline-flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .product-functional-buttons {
            margin-top: 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include media-breakpoint-down(xs) { /* max 543 */
              justify-content: center;
            }
            > div {
              display: inline-flex;
            }
            .btn-primary {
              background: transparent;
              border: none;
              color: #333333;
              box-shadow: none;
              padding: 0;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;
              margin: 0 5px 0 0;
            }
            .compare {
              .btn-primary {
                margin: 0;
              }
            }
          }
        }
      }
      .image-block {
        position: relative;
        background: #fff;
        overflow: hidden;
        @include media-breakpoint-down(sm) {
          /* max 767 */
          flex: 0 0 40%;
          max-width: 40%;
        }
        @media (max-width: 480px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .right-product {  
        display: flex;
        flex: 1;
        @include media-breakpoint-down(sm) {
          /* max 767 */
          flex-flow: column nowrap;
        }
        @media (max-width: 480px) {
          margin-top: 15px;
        }
      }
      .col-description {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        @include media-breakpoint-down(sm) {
          /* max 767 */
        }
        .product-title {
          margin: 0;
        }
        .product-reference {
          margin-top: 5px;
        }
        .comments_note {
          margin-top: 10px;
        }
        .product-description-short {
          margin-top: 10px;
        }
        .products-variants {
          margin-top: 10px;
        }
        .product-availability {
          margin-top: 5px;
        }
      }
      .col-buy {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-end;
        @include media-breakpoint-down(sm) {
          /* max 767 */
          margin-top: 10px;
          align-items: flex-start;
        }
        .regular-price {
          font-size: 20px;
          font-weight: 500;
        }
        .product-functional-buttons {
          .product-functional-buttons-links {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-end;
            @include media-breakpoint-down(sm) {
              /* max 767 */
              align-items: flex-start;
            }
            .product-add-to-cart {
              margin-top: 10px;
              .btn {
                padding: 5px 15px;
                border-radius: 3px;
              }
            }
            .button-action-botom {
              margin-top: 10px;
              > a {
                background: transparent;
                border: none;
                box-shadow: none;
                color: #888;
                padding: 0;
                width: 30px;
                height: 30px;
                display: inline-flex;
                align-items: center;
                justify-content: flex-end;
                margin: 0 10px 0 0;
                &:last-child {
                  margin: 0;
                }
                i {
                  font-size: 16px;
                }
                span {
                  display: none;
                }
              }
            }
          }
        }
      }
      .center-block {
        .products-variants {
          margin-top: 10px;
        }
        .product-list-reviews {
          margin-top: 10px;
          position: relative;
          top: auto;
          padding: 0;
          justify-content: flex-start;
          .star-content {
            margin: 0;
          }
          .comments-nb {
            display: none;
          }
        }
      }
    }
  }
}
.products {
  .product-miniature {
    .product-container {
      position: relative;
      margin: 0 auto;
      max-width: 100%;
      flex: 0 0 100%;
      @include transition(all 0.3s ease 0s);
      @include media-breakpoint-only(xs) {
        max-width: 370px;
      }
      .thumbnail-container {
        position: relative;
        .thumbnail-inner {
          position: relative;
          overflow: hidden;
        }
        .product-thumbnail {
          display: block;
          text-align: center;
          position: relative;
          img {
            margin: 0 auto;
            max-width: 100%;
            height: auto;
            @include transition(all 0.7s ease 0s);
            &.img_1 {
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              opacity: 0;
            }
          }
        }
      }
      .product-list-reviews {
        visibility: visible;
        background: transparent;
        padding: 0;
        width: auto;
        .comments-nb {
          display: none;
        }
      }
      .variant-links {
        align-items: center;
        justify-content: center;
        .color {
          display: inline-flex;
          border-radius: 100%;
          width: 15px;
          height: 15px;
          margin-top: 3.5px;
          margin-bottom: 3.5px;
        }
        .count {
          color: $gray-dark;
          font-weight: 700;
          position: relative;
          vertical-align: middle;
        }
      }
      .tddeal {
        position: absolute;
        padding: 5px;
        width: 100%;
        bottom: 8px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .tdcountdown {
          display:inline-flex;
          padding: 6px 20px;
          border-radius: 3px;
          > div {
            display:inline-flex;
            padding: 0 8px 0 0;
            margin: 0 3px 0 0;
            position: relative;
            &:after {
              content: ":";
              position: absolute;
              right: 0;
              top: 0;
            }
            &.seconds_container {
              padding: 0;
              margin: 0;
              &:after {
                content: "";
              }
            }
            span {
              display: block;
              text-align: center;
            }
            .number {
              position: relative;
            }
            .text {
              display: none;
            }
          }
        }
      }
      &:hover {
        .thumbnail-inner {
          .product-thumbnail {
            img {
              @include transition(all 0.7s ease 0s);
              &.img_1 {
                opacity: 1;
              }
            }
          }
        }
        .tddeal {
          opacity: 0;
        }
        // .variant-links {
        //   transform: translateX(0px);
        // }
      }
    }
    // Style-1
    &.style-1 {
      .product-container {
        .variant-links {
          position: absolute;
          right: 10px;
          top: 10px;
          // align-items: center;
          // justify-content: center;
          transform: translateX(120%);
          @include transition(all 0.6s ease 0s);
        }
        .button-container {
          position: absolute;
          bottom: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          > div {
            @include transition(all 0.3s ease 0s);
            transform: scale(0, 0);
            position: relative;
          }
          .btn-primary {
            margin: 0 4px;
            box-shadow: none;
            padding: 0;
            height: 40px;
            width: 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            @include transition(all 0.3s ease 0s);
            i {
              display: inline-flex;
              justify-content: center;
              font-size: 18px;
            }
            span {
              font-size: 0;
            }
          }
          .wishlist {
            .td-list-wishlist {
              right: 0;
              left: auto;
            }
          }
        }
        .product-description {
          text-align: center;
          padding: 10px;
          .comments_note {
            justify-content: center;
            position: relative;
            margin-top: 5px;
          }
        }
        &:hover,
        &.hovered {
          .variant-links {
            transform: translateX(0px);
          }
          .button-container {
            > div {
              transform: scale(1, 1);
            }
          }
        }
      }
    }
    //style-2
    &.style-2 {
      .product-container {
        .tddeal {
          top:15px;
          bottom: auto;
        }
        .variant-links {
          top: auto;
          bottom: 15px;
          left: 10px;
          position: absolute;
          justify-content: flex-start;
          align-items: center;
          @include transition(all 0.5s ease 0s);
          transform: translateX(-150px);
        }
        .button-container {
          position: absolute;
          bottom: 15px;
          display: flex;
          align-items: stretch;
          justify-content: center;
          flex-flow: column nowrap;
          right: 15px;
          left: auto;
          opacity: 1;
          visibility: visible;
          transition: all 0.3s ease;
          > div {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            &:nth-child(1) {
              @include transition-delay(0.1s);
              transform: translateY(150px);
            }
            &:nth-child(2) {
              @include transition-delay(0.2s);
              transform: translateY(100px);
            }
            &:nth-child(3) {
              @include transition-delay(0.3s);
              transform: translateY(50px);
            }
            &:nth-child(4) {
              @include transition-delay(0.4s);
              transform: translateY(0px);
            }
          }
          &:hover,
          &.hovered {
            > div {
              transform: translateX(0px);
              opacity: 1;
              visibility: visible;
              transition: transform 0.5s ease;
              &:nth-child(1) {
                @include transition(transform 1s ease);
              }
              &:nth-child(2) {
                @include transition(transform 0.7s ease);
              }
            }
          }
          .product-add-to-cart {
            .btn-primary {
              margin: 0;
            }
          }
          .btn-primary {
            border: 0;
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
            margin: 0 0 10px;
            padding: 0;
            height: 40px;
            width: 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            @include transition(all 0.3s ease 0s);
            i {
              display: inline-flex;
              justify-content: center;
              font-size: 22px;
            }
            span {
              font-size: 0;
            }
          }
          .compare {
            margin: 0;
          }
        }
        .product-description {
          padding-top: 15px;
          overflow: hidden;
          text-align: left;
          .product-title {
            margin: 0;
          }
          .product-price-and-shipping {
            margin-top: 5px;
          }
          .comments_note {
            margin-top: 2px;
          }
        }
        &:hover,
        &.hovered {
          .variant-links {
            transform: translateX(0);
          }
        }
      }
    }
    //style-3
    &.style-3 {
      @include transition(box-shadow 0.3s ease-in-out);
      .product-container {
        .quick-view-wrapper {
          position: absolute;
          right: 0;
          top: 0;
          opacity: 0;
          @include transition(all 0.5s ease 0s);
          transform: scale(0, 0);
          .btn-primary {
            border-radius: 0;
            background: transparent;
            border: none;
            box-shadow: none;
            color: #333;
            padding: 0;
            height: 40px;
            width: 40px;
            line-height: 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            @include transition(all 0.3s ease 0s);
            i {
              display: inline-flex;
              justify-content: center;
              font-size: 22px;
            }
            span {
              font-size: 0;
            }
          }
        }
        .button-container {
          position: absolute;
          bottom: 15px;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          opacity: 0;
          @include transition(all 300ms ease);
          transform: translateY(15px);
          visibility: hidden;
          .btn-inner {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: orange;
          }
          > div {
            position: relative;
          }
          .wishlist {
            border-left: 1px solid rgba(255, 255, 255, 0.2);
            border-right: 1px solid rgba(255, 255, 255, 0.2);
          }
          .btn-primary {
            box-shadow: none;
            background: transparent;
            border: 0;
            padding: 0;
            height: 38px;
            width: 46px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            @include transition(all 0.3s ease 0s);
            i {
              display: inline-flex;
              justify-content: center;
              font-size: 18px;
            }
            span {
              font-size: 0;
            }
          }
        }
        .product-description {
          text-align: left;
          padding-top: 15px;
          .comments_note {
            margin-top: 5px;
          }
          .product-list-reviews {
            justify-content: center;
            position: relative;
            margin-top: 5px;
          }
          .products-variants {
            position: absolute;
            @include transition(all 400ms ease);
            visibility: hidden;
            opacity: 0;
            margin-top: 5px;
            left: -10px;
            right: -10px;
            top: 100%;
            background: #fff;
            z-index: 91;
            padding: 0 10px 10px 10px;
            .variant-links {
              justify-content: flex-start;
            }
          }
        }
      }
      &:hover,
      &.hovered {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        z-index: 91;
        .product-container {
          .quick-view-wrapper {
            transform: scale(1, 1);
            opacity: 1;
          }
          .button-container {
            opacity: 1;
            transform: translate(0);
            visibility: visible;
          }
          .products-variants {
            opacity: 1;
            visibility: visible;
            box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
    //style-left
    &.style-left {
      .product-container {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 100%;
        .thumbnail-container {
          flex: 0 0 auto;
          position: relative;
          .thumbnail-inner {
            position: relative;

            overflow: hidden;
            .product-thumbnail {
              display: block;
              text-align: center;
              position: relative;
              img {
                margin: 0 auto;
                width: 85px;
                height: auto;
              }
            }
          }
        }
        .product-flags {
          display: none;
        }
        .product-description {
          flex: 1;
          overflow: hidden;
          text-align: left;
          padding: 0 60px 0 20px;
          @include media-breakpoint-down(md) { /* max 991 */
            padding: 0 10px;
          }
          .product-title {
            margin: 0;
          }
          .comments_note {
            margin-top: 5px;
          }
          .product-price-and-shipping {
            margin-top: 2px;
          }
        }
      }
    }
  }
  @include media-breakpoint-only(xs) {
    /* max 543 */
    &.cols-xs-1 {
      .product-miniature {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    &.cols-xs-2 {
      .product-miniature {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  @include media-breakpoint-only(sm) {
    /* min 544 max 767 */
    &.cols-sm-1 {
      .product-miniature {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    &.cols-sm-2 {
      .product-miniature {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    &.cols-sm-3 {
      .product-miniature {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
  }
  @include media-breakpoint-only(md) {
    /* min 768 max 991 */
    &.cols-md-1 {
      .product-miniature {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    &.cols-md-2 {
      .product-miniature {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    &.cols-md-3 {
      .product-miniature {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
    &.cols-md-4 {
      .product-miniature {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
  @include media-breakpoint-only(lg) {
    /* min 992 max 1199 */
    &.cols-lg-1 {
      .product-miniature {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    &.cols-lg-2 {
      .product-miniature {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    &.cols-lg-3 {
      .product-miniature {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
    &.cols-lg-4 {
      .product-miniature {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
    &.cols-lg-5 {
      .product-miniature {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    &.cols-xl-1 {
      .product-miniature {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    &.cols-xl-2 {
      .product-miniature {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    &.cols-xl-3 {
      .product-miniature {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
    &.cols-xl-4 {
      .product-miniature {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
    &.cols-xl-5 {
      .product-miniature {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
    &.cols-xl-6 {
      .product-miniature {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
      }
    }
  }
}
.product-add-to-cart {
  form {
    width: auto;
    max-width: auto;
  }
}
