//fliter css
.facet-block {
  .custom-control {
    display: flex;
    flex-direction: column;
    align-content: center;
    min-height: 0;
    .custom-control-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      &:before,
      &:after {
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .custom-color {
    font-size: 0;
    display: inline-flex;
    flex-direction: row;
    .color {
      margin: 0 8px 0 0;
    }
  }
}

.facet-block:not(:last-child) {
  /*  margin-bottom: $spacer; */
  border-bottom: 1px solid $card-border-color;
  padding-bottom: $spacer;
}
.facet-block {
  .remove {
    display: none;
  }
}
//responsive filter
#offcanvas_search_filter {
  .modal-header {
    background: #f5f5f5;
    color: $gray-darker;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    border:0;
    .close {
      background: #000;
      opacity: 1;
      span {
        color: #ffffff!important;
        font-size: 22px!important;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  p.title_block {
    display: none;
  }
}
#search_filters {
  margin-bottom: 0;
  > h4 {
    display: block;
    margin: 0;
  }
  .ui-slider-horizontal {
    height: .4em;
    .ui-slider-handle {
      margin-left: -1px;
      cursor: pointer;
    }
  }
  .ui-widget-header {
   background: #555;
 }
 .facet {
  .faceted-slider {
    margin-bottom: 10px;
    .ui-slider {
      height: 7px;
      border: none;
      margin: 0;
      background: #ddd;
      border-radius: 10px;
      .ui-slider-range {
        background: #000;
      }
      .ui-slider-handle {
        top: 0;
        width: 15px;
        height: 15px;
        background: #fff;
        border: 3px solid #000;
        border-radius: 25px;
        bottom: 0;
        margin: auto;
      }
      .ui-slider-handle:last-child {
        margin-left: -15px;
      }
    }
  }
  .color_facet {
    display: flex;
    margin: 0;
    li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .facet-label {
        padding: 0;
        .custom-control {
          padding: 0;
        }
      }
      &:last-child {
        .color {
          margin: 0;
        }
      }
    }
  }
  ul {
    margin-bottom: 0;
  }
  .collapse {
    display: block;
  }
  .facet-title {
    margin-bottom: 0;
    padding: 10px 0;
    display: inline-block;
    font-weight: normal;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .facet-label {
    // display: inline-flex;
    // justify-content: space-between;
    // align-items: center;
    padding: 5px 0;
    margin: 0;
    width: 100%;
    text-align: left;
    a {
      width: 100% !important;
      overflow: visible !important;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 30px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; 
      .magnitude {
        margin-left: 10px;
      }
    }
    .input-color {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      position: absolute;
      cursor: pointer;
      opacity: 0;
      &:checked {
        + span {
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
          &:before {
            visibility: visible;
            text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
            opacity: 1;
            filter: alpha(opacity=100);
            border: none;
          }
        }
      }
      &:hover {
        + span {
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        }
      }
    }
    .color {
      margin: 5px 5px 5px 0;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      border: 3px solid #fff;
      position: relative;
      @include transition(opacity 0.3s ease 0s);
      vertical-align: top;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
      &:before {
        content: "\f00c";
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-icon;
        position: absolute;
        color: #f0f0f0;
        font-size: 12px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        filter: alpha(opacity=0);
        @include transition(opacity 0.3s ease 0s);
      }
    }
  }
  .title {
    cursor: pointer;
    position: relative;
    .collapse-icons .remove {
      display: none;
    }
  }
  .title[aria-expanded="true"] {
    .collapse-icons {
      .add {
        display: none;
      }
      .remove {
        display: block;
      }
    }
  }
  .navbar-toggler {
    display: inline-block;
    font-size: 20px;
    height: 30px;
    padding: 6px 0;
    text-align: center;
    vertical-align: middle;
    i {
      color: currentcolor;
    }
  }
  .collapse {
    display: none;
    &.show {
      display: block;
    }
  }
}
}
//pagination
.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 20px 0 0;
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border: 1px solid $border-color;
      margin-right: 5px;
      a {
        i {
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}

.active_filters {
  .close {
    color: currentcolor;
    font-size: 12px;
    opacity: 1;
  }
}

.block-categories {
  .category-top-menu {
    margin: 0;
  }
  .category-sub-menu {
    li {
      position: relative;
      border: none;
      padding: 0;
      > a {
        display: block;
        padding: 5px 0;
        i {
          margin-right: 5px;
        }
      }
      .category-sub-menu {
        padding: 0 0 0 15px;
      }
    }
    .icon-collapse {
      position: absolute;
      right: 0;
      top: 5px;
      padding: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

.faceted-overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(25, 25, 25, 0.5);
  z-index: 100; 
  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .spinner {
    width: 60px;
    height: 60px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.08);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
}