.elementor-brands {
  position: relative;
  .block_content {
    margin: 0 30px;
  }
  .grid {
    @include media-breakpoint-only(xs) {
      /* max 543 */
      &.cols-xs-1 {
        .item {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      &.cols-xs-2 {
        .item {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
    @include media-breakpoint-only(sm) {
      /* min 544 max 767 */
      &.cols-sm-1 {
        .item {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      &.cols-sm-2 {
        .item {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
      &.cols-sm-3 {
        .item {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
    }
    @include media-breakpoint-only(md) {
      /* min 768 max 991 */
      &.cols-md-1 {
        .item {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      &.cols-md-2 {
        .item {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
      &.cols-md-3 {
        .item {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
      &.cols-md-4 {
        .item {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
    }
    @include media-breakpoint-only(lg) {
      /* min 992 max 1199 */
      &.cols-lg-1 {
        .item {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      &.cols-lg-2 {
        .item {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
      &.cols-lg-3 {
        .item {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
      &.cols-lg-4 {
        .item {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
      &.cols-lg-5 {
        .item {
          flex: 0 0 20%;
          max-width: 20%;
        }
	  }
	  &.cols-lg-6 {
        .item {
          flex: 0 0 16.66667%;
          max-width: 16.66667%;
        }
	  }
	  &.cols-lg-7 {
        .item {
          flex: 0 0 14.2%;
          max-width: 14.2%;
        }
	  }
	  &.cols-lg-8 {
        .item {
          flex: 0 0 12.5%;
          max-width: 12.5%;
        }
	  }
    }
    @include media-breakpoint-up(xl) {
      &.cols-xl-1 {
        .item {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      &.cols-xl-2 {
        .item {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
      &.cols-xl-3 {
        .item {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
      &.cols-xl-4 {
        .item {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
      &.cols-xl-5 {
        .item {
          flex: 0 0 20%;
          max-width: 20%;
        }
      }
      &.cols-xl-6 {
        .item {
          flex: 0 0 16.66667%;
          max-width: 16.66667%;
        }
	  }
	  &.cols-xl-7 {
        .item {
          flex: 0 0 14.2%;
          max-width: 14.2%;
        }
	  }
	  &.cols-xl-8 {
        .item {
          flex: 0 0 12.5%;
          max-width: 12.5%;
        }
	  }
	  &.cols-xl-9 {
        .item {
          flex: 0 0 11.11%;
          max-width: 11.11%;
        }
	  }
	  &.cols-xl-10 {
        .item {
          flex: 0 0 10%;
          max-width: 10%;
        }
      }
    }
  }
  .manufacturer-carousel {
    margin-bottom: 0;
  }
  .item {
    text-align: center;
    img {
      max-width: 100%;
      opacity: 0.6;
      @include transition(all 0.3s ease 0s);
    }
    span {
      margin-top: 10px;
      display: block;
    }
    &:hover {
      img {
        opacity: 1;
      }
    }
  }
}
